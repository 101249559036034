<template>
  <div class="login-content__forget">
    <div class="login-content__forget__label _font-weight__600 _font-size__heading3">
      Forget password
    </div>
    <el-form
      ref="form"
      size="medium"
      :model="forgetForm"
      :rules="rules"
      class="login-content__forget__form"
      :style="{ marginBottom: editPwd ? '40px' : '115px' }"
    >
      <el-form-item prop="email" v-if="!editPwd">
        <el-input v-model="forgetForm.email" clearable placeholder="Email Address"></el-input>
      </el-form-item>
      <!-- 循环遍历 -->
      <template v-else>
        <div v-for="item in pwdInputs" :key="item.property">
          <el-form-item :prop="item.property">
            <el-input
              v-model="forgetForm[item.property]"
              :type="item.isView ? 'text' : 'password'"
              :placeholder="item.placeholder"
              @blur="isShowRuleError = true"
            >
              <img
                slot="suffix"
                :src="item.isView ? visibility : visibilityOff"
                @click="item.isView = !item.isView"
              />
            </el-input>
          </el-form-item>

          <div class="rules-error" v-if="item.property === 'newPassword'">
            <Transition name="el-zoom-in-top">
              <span v-show="isShowRuleError && forgetForm.newPassword.length < 8"
                >Enter at least 8 characters</span
              >
            </Transition>

            <Transition name="el-zoom-in-top">
              <span v-show="isShowRuleError && !/[a-zA-Z]/.test(forgetForm.newPassword)">
                Contain at least 1 English character
              </span>
            </Transition>
          </div>
        </div>
      </template>
    </el-form>

    <el-button
      type="primary"
      class="login-content__forget__send"
      :loading="loading"
      @click="handleSendLink"
    >
      {{ editPwd ? 'Save Changes' : 'Send Reset Password Link' }}
    </el-button>

    <div
      v-if="isTip && !editPwd"
      class="login-content__forget__send-success _font-weight__400 _font-size__sub__heading"
    >
      Reset password link sent to your email address.
    </div>
    <div
      class="login-content__forget__to-login primary-color _font-weight__600 _font-size__sub__heading _pointer"
      @click="$emit('switchComponent', 'LoginForm')"
    >
      Go to Login Page
    </div>
  </div>
</template>

<script>
import visibilityOff from '@/assets/icons/login/visibility-off.png'
import visibility from '@/assets/icons/login/visibility.png'
import Cookies from 'js-cookie'
import { Decrypt, Encrypt } from '@/utils/ASE-secret'
import { sendMailResetPassword, resetPwd } from '@/api/auth'
import { sendToEmail, TYPE_ENUM, emailRules, urlToObj } from './index'

export default {
  data() {
    return {
      visibilityOff,
      visibility,
      isShowRuleError: false,
      loading: false,
      isTip: false,
      editPwd: false,
      pwdInputs: [
        {
          property: 'newPassword',
          placeholder: 'New Password',
          isView: false
        },
        {
          property: 'confirmPassword',
          placeholder: 'Confirm New Password',
          isView: false
        }
      ],
      rules: {
        email: emailRules,
        newPassword: {
          trigger: ['change', 'blur'],
          validator: (rule, value, callback) => {
            if (value === '' || value.length < 8 || !/[a-zA-Z]/.test(value)) {
              callback('Please enter a valid password')
            } else {
              callback()
            }
          }
        },
        confirmPassword: {
          trigger: ['change', 'blur'],
          validator: (rule, value, callback) => {
            if (this.forgetForm.newPassword !== value) {
              callback('The entered password is inconsistent')
            } else {
              callback()
            }
          }
        }
      },
      forgetForm: {
        email: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  mounted() {
    const { params } = this.$route.query
    if (params) {
      const paramData = urlToObj(params)
      // 从邮箱进来会携带 type, 在进行比较
      if (paramData.type === TYPE_ENUM.FORGET_PWD) {
        Cookies.set('email', Encrypt(paramData.email))
        this.editPwd = true
      }
    }
  },
  methods: {
    handleSendLink() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          // 发送连接到邮箱逻辑
          if (!this.editPwd) {
            const url = sendToEmail(this.forgetForm, TYPE_ENUM.FORGET_PWD)
            sendMailResetPassword(this.forgetForm.email, url)
              .then((res) => {
                if (res.code === 200) {
                  this.isTip = true
                } else {
                  this.$message.error(res.message)
                }
                this.loading = false
              })
              .catch((res) => {
                this.$message.error(res.message)
                this.loading = false
              })
          } else {
            // 重置密码逻辑
            resetPwd({
              code: this.$route.query.code,
              loginName: Decrypt(Cookies.get('email')),
              newPwd: this.forgetForm.newPassword
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success('重置密码成功！')
                  // 清除状态
                  this.editPwd = false
                  Cookies.remove('password')
                  localStorage.removeItem('isRemember')
                  this.$emit('switchComponent', 'LoginForm')
                  setTimeout(() => {
                    this.$router.push('/login')
                  })
                } else {
                  this.$message.error(res.message)
                }
                this.loading = false
              })
              .catch((res) => {
                this.$message.error(res.message)
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
